import { getFragmentData } from '~/gql';
import {
  AttributeTaskFragmentDoc,
  JobQuery,
  TaskDataFragmentDoc,
  TaskInput,
} from '~/gql/graphql';
import { extractProperty } from '~/helpers/collection';

export { EditJobForm } from './EditJobForm';

export type Job = NonNullable<JobQuery['job']>;
export type Task = Job['tasks'][0];

export function taskToInput(data: Task): TaskInput {
  const task = getFragmentData(TaskDataFragmentDoc, data);
  const attributeAudit = getFragmentData(AttributeTaskFragmentDoc, task);

  return {
    id: task.id,
    type: task.type,
    name: task.name,
    description: task.description,
    required: task.required,
    tagIds: extractProperty(task.tags?.nodes, 'id'),
    attributes: task.attributes?.map(({ id }) => id),

    // Asset
    asset: task.assets
      ? {
          assets: task.assets?.map((asset) => asset.id),
          deploy: undefined,
        }
      : undefined,

    // Attribute
    attribute: attributeAudit.attribute
      ? { attributes: attributeAudit.attribute.attributes }
      : { attributes: [] },

    // Item/Restock
    // TODO ...

    // Purchase
    purchase: task.purchase ? { spaces: task.purchase.spaces } : { spaces: [] },

    // Stocktake
    stocktake: task.stocktake
      ? {
          include: task.stocktake?.include,
          itemStatus: task.stocktake?.itemStatus,
          spaces: task.stocktake?.spaces,
        }
      : undefined,

    // Transfer
    // TODO ...
  };
}
