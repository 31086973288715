import { yupResolver } from '@hookform/resolvers/yup';
import qs from 'qs';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { FullMono, Square } from '~/brand/generated/propfit.app';
import { Input } from '~/components/form/Input';
import { post } from '~/helpers/fetch';

type FormData = {
  id: string;
  token: string;
  password: string;
  passwordConfirmation: string;
};

const VALIDATION_SCHEMA = Yup.object().shape({
  password: Yup.string().min(8).required('Required'),
  passwordConfirmation: Yup.string().min(8).required('Required'),
});

export const ResetPassword = () => {
  const location = useLocation();
  const [done, setDone] = useState(false);
  const { t } = useTranslation('auth');

  const { id, job, token } = useMemo(() => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    return {
      id: query.id?.toString() ?? '',
      job: query.job?.toString() ?? '',
      token: query.t?.toString() ?? '',
    };
  }, [location]);

  const {
    formState: { errors, isSubmitting },
    register,
    handleSubmit,
  } = useForm<FormData>({
    defaultValues: {
      id,
      token,
      password: '',
      passwordConfirmation: '',
    },
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const resetPassword = async (values: FormData) => {
    try {
      await post('/api/reset-password', values);
      setDone(true);
      if (job) {
        window.location.href = job === '0' ? '/' : '/jobs/' + job;
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  if (done) {
    return (
      <div className='flex w-screen items-center justify-center h-screen'>
        <div className='w-full lg:w-1/2'>
          <div className='mx-auto flex flex-col justify-center rounded-lg px-10 py-24 md:w-4/5 md:border md:border-gray88 lg:mx-auto lg:w-[30rem] '>
            <div className='mb-8 flex flex-col items-center justify-center text-center'>
              <Square className='mx-auto h-20' />
            </div>
            <p>
              You can now{' '}
              <Link className='text-brand underline' to='/login?pw=1'>
                login.
              </Link>
            </p>
          </div>
        </div>
        <div
          className={
            'hidden items-center justify-center bg-gradient-to-b from-brand to-slate-dark h-screen lg:flex lg:w-1/2'
          }
        >
          <FullMono className='w-1/2' />
        </div>
      </div>
    );
  }

  return (
    <div className='flex w-screen items-center justify-center h-screen'>
      <div className='w-full lg:w-1/2'>
        <form
          onSubmit={handleSubmit(resetPassword)}
          className='mx-auto flex-1 rounded-lg px-10 py-16 md:w-4/5 md:border md:border-gray88 lg:mx-auto lg:w-[30rem] lg:flex-initial'
        >
          <div className='mb-14 flex flex-col items-center justify-center'>
            <Square className='mx-auto h-20' />
            <h1 className='mt-5 text-2xl font-medium'>
              {job ? t('setPassword') : t('resetPassword')}
            </h1>
            <p className='mb-8'>
              {job
                ? 'Enter a password for your account'
                : t('reset.instruction')}
            </p>
          </div>
          <Input
            {...register('password')}
            label={job ? t('password') : t('newPassword')}
            type='password'
            error={errors.password?.message}
          />
          <Input
            {...register('passwordConfirmation')}
            label={job ? t('confirmPassword') : t('confirmNewPassword')}
            type='password'
            error={errors.passwordConfirmation?.message}
          />
          <button
            className='mb-4 mt-8 w-full rounded-lg bg-brand p-3 font-semibold uppercase text-white transition-all duration-300 hover:bg-slate-dark  disabled:opacity-50'
            disabled={isSubmitting}
          >
            {job ? t('setPassword') : t('resetPassword')}
          </button>
          {!job && (
            <div className='mt-2 text-center text-sm'>
              <Link className='font-normal underline' to='/login?pw=1'>
                {t('backToLogin')}
              </Link>
            </div>
          )}
        </form>
      </div>

      <div
        className={
          'hidden items-center justify-center bg-gradient-to-b from-brand to-slate-dark h-screen lg:flex lg:w-1/2'
        }
      >
        <FullMono className='w-1/2' />
      </div>
    </div>
  );
};
