import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  redirect,
  useLoaderData,
  useSubmit,
} from 'react-router-dom';
import { client } from '~/client';
import {
  AutomationForm,
  FormValues,
} from '~/components/automation/AutomationForm/AutomationForm';
import { graphql } from '~/gql';
import { AutomationInput } from '~/gql/graphql';
import { removeEmpty } from '~/helpers/object';

const AutomationFormDocument = graphql(`
  query AutomationForm($id: ID) {
    contacts(status: Active) {
      ...ContactFields
    }
    jobTemplates(status: Active) {
      value: id
      label: name
    }
    automation(id: $id) {
      id
      status
      name
      on
      sites {
        id
        name
        image
      }
      jobName
      template {
        id
      }
      owners {
        id
      }
      assignees {
        id
      }
      day
      time
    }
  }
`);

const CreateAutomationDocument = graphql(`
  mutation CreateAutomation($input: AutomationInput!) {
    createAutomation(input: $input)
  }
`);

export const serializeFormData = (data: FormValues) =>
  removeEmpty({
    ...data,
    sites: JSON.stringify(data.sites.map((site) => site.id)),
    owner: JSON.stringify(data.owner),
    assignee: JSON.stringify(data.assignee),
    day: data.day.toString(),
  });

export const parseFormData = (data: FormData) =>
  ({
    ...Object.fromEntries(data),
    runOnExistingBookings: data.get('runOnExistingBookings') === 'true',
    sites: JSON.parse(data.has('sites') ? (data.get('sites') as string) : '[]'),
    owner: JSON.parse(data.has('owner') ? (data.get('owner') as string) : '[]'),
    assignee: JSON.parse(
      data.has('assignee') ? (data.get('assignee') as string) : '[]'
    ),
    day: parseInt(data.get('day') as string),
  } as AutomationInput);

export async function action({ request }: ActionFunctionArgs) {
  const input = parseFormData(await request.formData());
  // const [,res2] = useMutation({ query: CreateAutomationDocument, variables: { input }})

  const res = await client
    .mutation(CreateAutomationDocument, {
      input,
    })
    .toPromise();

  const id = res.data?.createAutomation;
  if (id) {
    return redirect(`/automations/${res.data?.createAutomation}`);
  }
}

export async function loader({ params }: LoaderFunctionArgs) {
  const { data } = await client
    .query(
      AutomationFormDocument,
      { id: params.automationId },
      { requestPolicy: 'network-only' }
    )
    .toPromise();

  if (!data) {
    throw new Response('Error', { status: 500 });
  }

  return data;
}

export function Component() {
  const options = useLoaderData() as Awaited<ReturnType<typeof loader>>;
  const submit = useSubmit();

  return (
    <AutomationForm
      data={options}
      onSubmit={(data) =>
        submit(serializeFormData(data), {
          method: 'post',
          action: '/automations/new',
        })
      }
    />
  );
}
