import { Controller } from 'react-hook-form';
import { AssetSelect, useAssets } from '~/components/AssetSelect';
import { DeploymentFieldsFragment } from '~/components/AssetSelect/AssetSelect';
import { getFragmentData } from '~/gql';
import { ListAssetsQuery } from '~/gql/graphql';
import { notUndefined } from '~/helpers/filter';
import { Control } from '../CustomTaskForm';

type Asset = ListAssetsQuery['assets'][number];

type Props = {
  control: Control;
  locationId?: string;
};

function hasLocation(locationId: string | undefined) {
  return (asset: Asset) => {
    const deployment = getFragmentData(
      DeploymentFieldsFragment,
      asset.deployment
    );
    return (
      deployment &&
      `${deployment.location.__typename}:${deployment.location.id}` ===
        locationId
    );
  };
}

export function AssetTaskFields({ control, locationId }: Props) {
  const [assets] = useAssets();

  return (
    <>
      <Controller
        name='asset.assets'
        control={control}
        render={({ field: { value, onChange } }) => (
          <AssetSelect
            assets={assets.filter(hasLocation(locationId))}
            value={
              value && value.length
                ? value
                    .map((id) => assets.find((asset) => asset.id === id))
                    .filter(notUndefined)
                : []
            }
            onChange={(selected) => {
              onChange(selected.map((asset) => asset.id));
            }}
            multiple
          />
        )}
      />
    </>
  );
}
