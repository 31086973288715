import { useRouteError } from 'react-router-dom';

export function RootBoundary() {
  const error = useRouteError();

  console.error(error);

  // if (isRouteErrorResponse(error)) {
  //   return (
  //     <div>
  //       <h1>Oops</h1>
  //       <p>Status: {error.status}</p>
  //       <p>{error.data.message}</p>
  //     </div>
  //   );
  // }

  return (
    <div>
      Something went wrong
      <br />
      <a href='/jobs' className='text-blue underline'>
        Go back
      </a>
    </div>
  );
}
