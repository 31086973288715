import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { FullMono, Square } from '~/brand/generated/propfit.app';
import { Input } from '~/components/form/Input';
import { post } from '~/helpers/fetch';

type FormData = {
  username: '';
  password: '';
  rememberMe: false;
};

type LoginResult = {
  redirectTo?: string;
};

type Props = {
  back: () => void;
};

const VALIDATION_SCHEMA = Yup.object().shape({
  username: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
});

export const LoginUsingPassword = ({ back }: Props) => {
  const { t } = useTranslation('auth');
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
  } = useForm<FormData>({
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const login = async (values: FormData) => {
    try {
      const { data } = await post<LoginResult>('/api/sessions', values);

      const redirect = data.redirectTo ?? getRedirect();
      if (redirect) {
        window.location.href = redirect;
      } else {
        window.location.reload();
      }
    } catch (err) {}
  };

  return (
    <div className='flex w-screen items-center justify-center h-screen'>
      <div className='w-full lg:w-1/2'>
        <form
          onSubmit={handleSubmit(login)}
          className='mx-auto flex flex-1 flex-col justify-center rounded-lg px-10 py-16 md:w-4/5 md:border md:border-gray88 lg:mx-auto lg:w-[30rem] lg:flex-initial'
        >
          <div className='mb-12 flex flex-col items-center justify-center'>
            <Square className='mx-auto h-20' />
            <h1 className='mt-5 text-center text-2xl font-medium'>
              {t('signInText')}
            </h1>
          </div>
          <Input
            {...register('username')}
            label={t('emailAddress')}
            type='email'
            error={errors.username?.message}
          />
          <Input
            {...register('password')}
            label={t('password')}
            type='password'
            error={errors.password?.message}
          />
          <div className='mb-5 flex justify-between text-center text-sm'>
            <div className='flex flex-row-reverse items-center justify-center gap-1 text-left'>
              <label htmlFor='rememberMe'>{t('rememberMe')}</label>
              <input
                id='rememberMe'
                {...register('rememberMe')}
                type='checkbox'
                value='rememberMe'
                className='h-5 w-5 rounded border border-gray88 px-3 py-2.5 '
              />
            </div>

            <Link
              className='text-right font-normal underline'
              to='/forgot-password'
            >
              {t('forgotPassword')}
            </Link>
          </div>
          <button
            className='w-full rounded-lg bg-brand p-3 font-semibold uppercase text-white transition-all duration-300 hover:bg-slate-dark  disabled:opacity-50'
            disabled={isSubmitting}
          >
            {t('login')}
          </button>
          <div className='m-4 inline-flex h-6 items-center justify-center gap-2.5'>
            <div className='h-px w-full bg-black'></div>
            <div className="text-center font-['Figtree'] text-base font-medium leading-snug">
              OR
            </div>
            <div className='h-px w-full bg-black'></div>
          </div>

          <button
            type='button'
            onClick={back}
            className='mb-4 w-full rounded-lg border border-brand bg-white p-3 font-semibold uppercase text-brand'
          >
            log in with one time passcode
          </button>
        </form>
      </div>

      <div
        className={
          'hidden items-center justify-center bg-gradient-to-b from-brand to-slate-dark h-screen lg:flex lg:w-1/2'
        }
      >
        <FullMono className='w-1/2' />
      </div>
    </div>
  );
};

function getRedirect(): string | undefined {
  try {
    const redirect = window.sessionStorage.getItem('redirectTo');
    if (!redirect) return;
    const obj = JSON.parse(redirect);
    if (obj && obj.pathname && obj.t) {
      if (new Date().getTime() - obj.t > 1000 * 60 * 15) {
        // Expired
        return;
      }
      return obj.pathname;
    }
  } catch (error) {}
}
