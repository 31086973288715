import classNames from 'classnames/bind';
import { useField } from 'formik';
import { useCallback } from 'react';
import { Option } from '~/types';
import styles from './CheckRadio.module.css';
import { Checkbox } from './FilterToggle';

const cx = classNames.bind(styles);

type Props = {
  name: string;
  title: string;
  options?: Option[];
  onChange?: (value: string) => void;
};

export const CheckboxGroup = ({ name, title, options = [] }: Props) => {
  const [, meta] = useField(name);
  return (
    <fieldset className={cx('fieldset', { flex: false })}>
      <legend className={styles.legend}>{title}</legend>
      {options.map((option) => (
        <Checkbox key={option.value} name={name} {...option} />
      ))}
      {meta.error && <p className='ErrorMessage'>{meta.error}</p>}
    </fieldset>
  );
};

type RadioGroupProps = {
  /** A name is required for radio inputs */
  name: string;
  title: React.ReactNode;
  options: Option[];
  error?: string;
  value: string;
  onChange: (value: string) => void;
};

export const RadioGroup = ({
  name,
  title,
  options = [],
  error,
  value,
  onChange,
}: RadioGroupProps) => {
  return (
    <fieldset>
      <legend className='mb-4 text-sm'>{title}</legend>
      {options.map((option) => (
        <label key={option.value} className='mb-4 block pl-4 text-sm'>
          <input
            className='mr-4 align-text-top'
            type='radio'
            name={name}
            value={option.value}
            checked={option.value === value}
            onChange={(event) => onChange(event.target.value)}
          />{' '}
          {option.label}
        </label>
      ))}
      {error && <p className='ErrorMessage'>{error}</p>}
    </fieldset>
  );
};

export const RadioGroupField = (
  props: Pick<RadioGroupProps, 'name' | 'title' | 'options'>
) => {
  const [field, meta, helpers] = useField<string | string[]>(props.name);
  const { setValue } = helpers;

  const value = Array.isArray(field.value) ? field.value[0] : field.value;
  const isArray = Array.isArray(field.value);

  const handleChange = useCallback(
    (newValue: string) => setValue(isArray ? [newValue] : newValue),
    [isArray, setValue]
  );

  return (
    <RadioGroup
      {...props}
      error={meta.error}
      value={value}
      onChange={handleChange}
    />
  );
};
