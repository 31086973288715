import { Form, Formik, useFormikContext } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from 'urql';
import { AttributeView } from '~/components/AttributeView';
import { FilesF } from '~/components/FileLink';
import { PromptEffect } from '~/components/Prompt';
import { TagEntityType } from '~/components/TagCombobox';
import { useTags } from '~/components/TagCombobox/tags';
import { AttributeSelect_Query } from '~/components/form/AttributeSelect';
import { Button } from '~/components/form/SubmitButton';
import { ReadOnlyEditor } from '~/components/form/editor/ReadOnlyEditor';
import { CardSmall } from '~/components/ui/CardSmall';
import { HelperPopup } from '~/components/ui/HelperPopup';
import { ReadValue } from '~/components/ui/ReadValue';
import { StatusBadge } from '~/components/ui/StatusBadge';
import { AttachmentFields } from '~/components/upload/AttachmentFields';
import { toAttachmentInput } from '~/components/upload2';
import { UploadField, UploadResult } from '~/components/upload2/Upload';
import {
  ChecklistItem,
  JobStatus,
  useCompleteCustomTaskMutation,
} from '~/generated/graphql';
import { getFragmentData, graphql } from '~/gql';
import { JobQuery, TaskQuery } from '~/gql/graphql';
import { extractProperty } from '~/helpers/collection';
import { SideLayout } from '~/layouts/side/SideLayout';
import { TextAreaField } from '../../form/textarea/TextArea';
import { StartJobButton } from '../JobView';
import { MemoGallery } from '../JobView/Feed/Gallery';
import {
  ResetTaskStatusButton,
  // PauseTaskButton,
  // QuitTaskButton,
  // ResumeTaskButton,
  // StartTaskButton,
  WorkflowButtons,
} from '../WorkflowButtons';
import { ReadOnlyTransferTask } from './TransferTask/ReadOnlyTransferTask';
import { TransferTaskField } from './TransferTask/TransferTaskField';
import { UpdateTaskTags, UpdateTaskTagsField } from './UpdateTaskTags';

type Props = {
  enabled: boolean;
  job: NonNullable<JobQuery['job']>;
  task: TaskQuery['task'];
  onClose: () => void;
  onSuccess: () => void;
  template?: boolean;
};

type TaskFormData = {
  date: null;
  tags: string[];
  notes: string;
  attachments: UploadResult[];
  transfer: {
    selected: never[];
  };
};

const AddTagsToTaggable = graphql(`
  mutation AddTagsToTaggable($input: AddTagsToTaggableInput!) {
    addTagsToTaggable(input: $input) {
      __typename
      ... on Task {
        id
      }
      tags {
        nodes {
          id
        }
      }
    }
  }
`);

const RemoveTagsFromTaggable = graphql(`
  mutation RemoveTagsFromTaggable($input: RemoveTagsFromTaggableInput!) {
    removeTagsFromTaggable(input: $input) {
      __typename
      ... on Task {
        id
      }
      tags {
        nodes {
          id
        }
      }
    }
  }
`);

export const Custom = (props: Props) => {
  const { job, task } = props;
  const [, completeTask] = useCompleteCustomTaskMutation();

  const savedJobTasks = localStorage.getItem(`job.tasks_${job.id}`)
    ? JSON.parse(localStorage.getItem(`job.tasks_${job.id}`) || '')
    : null;

  const savedTaskForm: {
    taskId: string;
    notes: string;
    attachments: UploadResult[];
  } | null =
    savedJobTasks && savedJobTasks[`task.form_${task.id}`]
      ? savedJobTasks[`task.form_${task.id}`]
      : null;

  const initialValues = {
    date: null,
    tags: extractProperty(task.tags?.nodes, 'id'),
    notes: savedTaskForm?.notes ?? task?.notes ?? '',
    // images: savedTaskForm?.images || ([] as string[]),
    attachments:
      savedTaskForm?.attachments ??
      task.attachments?.map((attachment) => {
        const data = getFragmentData(AttachmentFields, attachment);
        return {
          id: data.id,
          kind: data.kind as 'image' | 'file',
          name: data.originalFilename,
          preview: data.thumb,
        };
      }) ??
      [],
    transfer: {
      selected: [],
    },
  };

  const handleTaskFormSubmit = async (values: TaskFormData) => {
    // Find any checklist items that need saving
    let checklist: ChecklistItem[] = [];
    document
      .querySelectorAll<HTMLInputElement>('.js-custom-task-checklist-item')
      .forEach((el) => {
        const key = el.getAttribute('name');
        if (key) checklist.push({ key, checked: el.checked === true });
      });

    const input = {
      id: task.id,
      date: values.date,
      tagIds: values.tags,
      notes: values.notes,
      attachments: values.attachments.map(toAttachmentInput),
      checklist,
      transfer: values.transfer,
    };

    const res = await completeTask({ input });

    if (res.error) {
      toast.error(res.error.message.replace('[GraphQL] ', ''));
      return;
    }

    props.onSuccess();
  };

  // FIXME: makes little sense...
  if (job.name === 'Template' && job.jobNumber === 'J0000') {
    return <TaskView template {...props} />;
  }

  if (
    job.status !== JobStatus.InProgress ||
    task.status === JobStatus.Complete
  ) {
    return <TaskView {...props} />;
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleTaskFormSubmit}>
      <TaskForm {...props} />
    </Formik>
  );
};

/**
 * Read only task view
 */
const TaskView = ({ job, task, onClose, template = false }: Props) => {
  const { t } = useTranslation(['translation', 'job']);
  const description = ReadOnlyEditor({ content: task.description });
  const [result] = useQuery({ query: AttributeSelect_Query });
  const attributesList = result?.data?.attributes;

  const needToStartJob = job.status === JobStatus.Accepted;
  const [shakeButton, setShakeButton] = useState<string>('');

  const [, addTags] = useMutation(AddTagsToTaggable);
  const [, removeTags] = useMutation(RemoveTagsFromTaggable);
  const [tagOptions] = useTags(TagEntityType.Task);

  function shakeStartButton() {
    setShakeButton('wiggle-btn');
    setTimeout(() => {
      setShakeButton('');
    }, 1000);
  }

  const showMandatoryTaskHelper =
    task.required &&
    task.status !== JobStatus.Complete &&
    task.status !== JobStatus.Incomplete;

  // We have to memoize images to prevent a re-render closing the lightgallery modal
  const images = useMemo(() => {
    return (
      task.attachments
        ?.map((attachment) => getFragmentData(AttachmentFields, attachment))
        .filter(({ kind }) => kind === 'image') ?? []
    );
  }, [task.attachments]);

  return (
    <SideLayout>
      <SideLayout.Head onClose={onClose}>{task.name}</SideLayout.Head>
      <SideLayout.Body onClick={() => shakeStartButton()}>
        {task.status !== JobStatus.Created && (
          <StatusBadge prefix={t('job:task')} value={task.status} />
        )}

        {showMandatoryTaskHelper && <MandatoryTaskHelper jobName={job.name} />}

        {description && (
          <>
            <div className='label mb-1.5'>{t('description')}</div>
            <div className='relative'>
              {/* Add overlay so the checkboxes are not clickable  */}
              <div className='absolute inset-0 z-20 bg-transparent'></div>
              <div className='mb-6'>{description}</div>
            </div>
          </>
        )}

        <UpdateTaskTags
          options={tagOptions}
          value={task.tags?.nodes}
          onChange={async (tags) => {
            const currentTags = extractProperty(task.tags?.nodes, 'id');
            const toAdd = tags.filter((id) => !currentTags.includes(id));
            const toRemove = currentTags.filter((id) => !tags.includes(id));
            if (toAdd.length) {
              await addTags({ input: { taggableId: task.id, tagIds: toAdd } });
            }
            if (toRemove.length) {
              await removeTags({
                input: { taggableId: task.id, tagIds: toRemove },
              });
            }
          }}
        />

        {task.type === 'Stocktake' ? (
          <>
            {task.stocktake?.include && (
              <ReadValue label='Include' className='whitespace-pre-line'>
                {task.stocktake.include
                  .map((type) => t(`itemType.${type}_plural`))
                  .join(', ')}
              </ReadValue>
            )}
            {task.stocktake?.itemStatus && (
              <ReadValue label='Item Status' className='whitespace-pre-line'>
                {task.stocktake.itemStatus.join(', ')}
              </ReadValue>
            )}
            {task.stocktake?.spaces && (
              <ReadValue
                label={t('space_plural')}
                className='whitespace-pre-line'
              >
                {task.stocktake.spaces.join(', ')}
              </ReadValue>
            )}
          </>
        ) : null}

        {task.attributes && task.attributes.length > 0 && (
          <ReadValue label='Attributes to Display'>
            {task.attributes.map((attr, idx) => (
              <p key={idx}>
                {(attr.category ? `${attr.category} \\ ` : '') + attr.name}
              </p>
            ))}
          </ReadValue>
        )}

        {template ? (
          <ReadValue label='Attributes to Audit'>
            {attributesList && attributesList.length
              ? attributesList
                  .filter((attr) =>
                    // @ts-ignore TOFIX
                    task.attribute?.attributes.includes(attr.id)
                  )
                  .map((attr, idx) => (
                    <p key={idx}>
                      {(attr.category ? `${attr.category} \\ ` : '') +
                        attr.name}
                    </p>
                  ))
              : null}
          </ReadValue>
        ) : (
          task.attributes &&
          task.attributes.length > 0 && (
            <AttributeView attributes={task.attributes} />
          )
        )}

        {task.assets?.map((asset) => (
          <CardSmall
            key={asset.id}
            linkTo=''
            titleText={asset.name}
            secondaryText={asset.deployment?.space.name}
            showImage
            image={asset.deployment?.image}
          />
        ))}
        <ReadValue label={t('notes')} className='whitespace-pre-line'>
          {task.notes}
        </ReadValue>

        <FilesF attachments={task.attachments ?? []} />
        <MemoGallery attachments={images} />

        {task.type === 'Transfer Stock' && job.location && task.transfer && (
          <ReadOnlyTransferTask task={task} />
          // <div className='-m-4'>
          //   <Stock location={job.location.__typename + ':' + job.location.id} />
          // </div>
        )}
      </SideLayout.Body>
      {!template && (
        <SideLayout.Foot className='p-4'>
          {needToStartJob && (
            <div className={shakeButton}>
              <StartJobButton id={job.id} />
            </div>
          )}
          <ResetTaskStatusButton id={task.id} jobStatus={job.status} />
        </SideLayout.Foot>
      )}
    </SideLayout>
  );
};
/**
 * This is the task form for DOING the task (perform task form)
 * This IS NOT the form for creating/editing the task
 */
const TaskForm = ({ enabled, job, task, onClose }: Props) => {
  const { values } = useFormikContext<TaskFormData>();
  const { t } = useTranslation(['translation', 'job']);
  const [uploading, setUploading] = useState(false);
  const isEnabled =
    enabled && [JobStatus.Created, JobStatus.InProgress].includes(task.status);

  const description = ReadOnlyEditor({ content: task.description });

  /**
   * This is to remember the user's inputs without completing the job
   */
  const { notes, attachments } = values;
  const taskFormValuesToSave = {
    taskId: task.id,
    notes,
    attachments,
  };
  if (notes || attachments.length > 0) {
    const savedJobTasks = localStorage.getItem(`job.tasks_${job.id}`)
      ? JSON.parse(localStorage.getItem(`job.tasks_${job.id}`) || '')
      : null;

    // TODO re-enable this feature after working out how it's going to work properly with attachments
    // debounce(() => {
    //   localStorage.setItem(
    //     `job.tasks_${job.id}`,
    //     JSON.stringify({
    //       ...savedJobTasks,
    //       [`task.form_${task.id}`]: taskFormValuesToSave,
    //     })
    //   );
    // }, 2000)();
  }

  const [tagOptions] = useTags(TagEntityType.Task);

  const required = task.required;
  const showMandatoryTaskHelper =
    required &&
    task.status !== JobStatus.Complete &&
    task.status !== JobStatus.Incomplete;

  return (
    <Form className='z-30 h-full w-full lg:w-auto'>
      <PromptEffect cancel='Stay with task' />
      <SideLayout>
        <SideLayout.Head onClose={onClose}>{task.name}</SideLayout.Head>
        <SideLayout.Body className='p-0'>
          {task.status !== JobStatus.Created && (
            <StatusBadge prefix={t('job:task')} value={task.status} />
          )}

          <div className='px-4'>
            {showMandatoryTaskHelper && (
              <MandatoryTaskHelper jobName={job.name} />
            )}

            {description && (
              <>
                <div className='label mb-1.5'>{t('description')}</div>
                <div className='mb-6'>{description}</div>
              </>
            )}

            {task.attributes && task.attributes.length > 0 && (
              <AttributeView attributes={task.attributes} />
            )}
          </div>

          {task.assets.map((asset) => (
            <CardSmall
              key={asset.id}
              linkTo=''
              titleText={asset.name}
              secondaryText={asset.deployment?.space.name}
              showImage
              image={asset.deployment?.image}
            />
          ))}

          {isEnabled && (
            <>
              <div className='flex flex-col gap-[15px] px-4'>
                <div>
                  <UpdateTaskTagsField options={tagOptions} />
                </div>

                <TextAreaField
                  name='notes'
                  label={t('notes')}
                  placeholder='Add Task Notes...'
                />
              </div>

              {task.type === 'Standard' && (
                <div className='text-center'>
                  <UploadField
                    id={task.id}
                    name='attachments'
                    attachments_v2={task.attachments ?? []}
                    onProgress={() => setUploading(true)}
                    onComplete={() => setUploading(false)}
                    multiple
                  />
                </div>
              )}
              {task.type === 'Transfer Stock' && (
                <TransferTaskField job={job} task={task} />
              )}
            </>
          )}
        </SideLayout.Body>
        <SideLayout.Foot className='p-4'>
          <WorkflowButtons
            id={task.id}
            jobStatus={job.status}
            taskStatus={task.status}
          >
            <CompleteTaskButton disabled={uploading} />
          </WorkflowButtons>
        </SideLayout.Foot>
      </SideLayout>
    </Form>
  );
};

export function MandatoryTaskHelper({ jobName }: { jobName: string }) {
  return (
    <div className='mb-3 flex items-center'>
      <HelperPopup
        icon={
          <div className='flex h-[18px] w-[18px] items-center justify-center rounded-full bg-orange'>
            <span className='text-[9px] font-bold text-white'>M</span>
          </div>
        }
      >
        You will need to complete or defer this task in order to complete{' '}
        {jobName}
      </HelperPopup>
      <span>Mandatory Task</span>
    </div>
  );
}

function CompleteTaskButton({ disabled }: { disabled?: boolean }) {
  const { t } = useTranslation('job');
  const { submitForm, setFieldValue } = useFormikContext();

  const handleClick = useCallback(() => {
    setFieldValue('date', new Date());
    submitForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Button type='button' disabled={disabled} onClick={handleClick}>
      {t('completeTask')}
    </Button>
  );
}
