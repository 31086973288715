import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import { client } from '~/client';
import { AutomationView } from '~/components/automation/AutomationView/AutomationView';
import { ListAutomationsDocument } from './automations._index';

export async function loader({ params }: LoaderFunctionArgs) {
  const { data } = await client
    .query(ListAutomationsDocument, {}, { requestPolicy: 'cache-and-network' })
    .toPromise();

  const automation = data?.automations.find(
    (automation) => automation.id === params.automationId
  );

  if (!automation) {
    throw new Response('Not found', { status: 404 });
  }

  return automation;
}

export function Component() {
  const automation = useLoaderData() as Awaited<ReturnType<typeof loader>>;

  return <AutomationView automation={automation} />;
}
