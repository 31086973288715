import {
  ActionFunctionArgs,
  redirect,
  useLoaderData,
  useSubmit,
} from 'react-router-dom';
import invariant from 'tiny-invariant';
import { client } from '~/client';
import { AutomationForm } from '~/components/automation/AutomationForm/AutomationForm';
import { graphql } from '~/gql';
import { loader, parseFormData, serializeFormData } from './automations.new';

export { loader };

const UpdateAutomationDocument = graphql(`
  mutation UpdateAutomation($id: ID!, $input: AutomationInput!) {
    updateAutomation(id: $id, input: $input)
  }
`);

export async function action({ params, request }: ActionFunctionArgs) {
  invariant(params.automationId, 'Missing automationId');
  const input = parseFormData(await request.formData());

  const res = await client
    .mutation(UpdateAutomationDocument, { id: params.automationId, input })
    .toPromise();

  const id = res.data?.updateAutomation;
  if (id) {
    return redirect(`/automations/${res.data?.updateAutomation}`);
  }
  return null;
}

export function Component() {
  const data = useLoaderData() as Awaited<ReturnType<typeof loader>>;
  const submit = useSubmit();

  return (
    <AutomationForm
      data={data}
      onSubmit={(data) => submit(serializeFormData(data), { method: 'post' })}
    />
  );
}
