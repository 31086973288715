import { yupResolver } from '@hookform/resolvers/yup';
import {
  isValidPhoneNumber,
  parsePhoneNumberFromString,
} from 'libphonenumber-js/mobile';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { useMutation } from 'urql';
import * as Yup from 'yup';
import { FullMono, Square } from '~/brand/generated/propfit.app';
import { Input } from '~/components/form/Input';
import { Label } from '~/components/ui/Label';
import { graphql } from '~/gql';
import { LoginUsingPassword } from './LoginUsingPassword';
import { VerifyForm } from './VerifyForm';

const DEFAULT_COUNTRY = 'AU';

type FormValues = {
  identifier: string;
  method?: 'email' | 'sms';
};

const OtpGenerate_Mutation = graphql(`
  mutation OtpGenerate($input: OtpGenerateInput!) {
    otpGenerate(input: $input) {
      success
      id
    }
  }
`);

const validationSchema = Yup.object().shape({
  // TODO figure out how to validate email or mobile number
  identifier: Yup.mixed()
    .required('Required')
    .test('is-valid', 'Enter a valid email or mobile number', (value) => {
      if (/^[A-Za-z0-9_-]+$/.test(value)) {
        return true;
      }
      if (value.includes('@')) {
        return Yup.string().email().isValidSync(value);
      }
      return isValidPhoneNumber(value, DEFAULT_COUNTRY);
    })
    .transform((value) => {
      if (value.includes('@')) {
        return value; // return the value as is if it's an email
      } else {
        const phoneNumber = parsePhoneNumberFromString(value, DEFAULT_COUNTRY);
        return phoneNumber?.number ?? value; // format the phone number if it's valid
      }
    }),
});

export const LoginForm = () => {
  const { t } = useTranslation('auth');
  const [result, otpGenerate] = useMutation(OtpGenerate_Mutation);
  const [searchParams] = useSearchParams();
  const [loginWithPassword, setLoginWithPassword] = useState(
    searchParams.get('pw') === '1' ? true : false
  );
  const [verify, setVerify] = useState<string | null>(null);

  const jobId = searchParams.get('j');
  const email = searchParams.get('e');
  const phone = searchParams.get('p');
  const token = searchParams.get('t');

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues: token
      ? {
          identifier: token,
          method: 'email',
        }
      : {
          identifier: '',
        },
    resolver: yupResolver(validationSchema),
  });

  const submit = async (values: FormValues) => {
    const { data } = await otpGenerate({
      input: values,
    });
    if (data?.otpGenerate.success && data.otpGenerate.id) {
      setVerify(data.otpGenerate.id);
    }
  };

  // const isMobile = value === '' || /^(\+?\d[\d\s]*)+$/.test(value);
  // console.log('isMobile', isMobile);

  if (loginWithPassword) {
    return <LoginUsingPassword back={() => setLoginWithPassword(false)} />;
  }

  if (verify) {
    return (
      <VerifyForm
        id={verify}
        redirectTo={jobId ? `/jobs/${jobId}` : null}
        onBack={setVerify}
      />
    );
  }

  const input = token ? (
    <>
      <Label as='div'>Send code via</Label>
      <label className='mb-3 ml-2 mt-1 flex gap-2'>
        <input type='radio' value='email' {...register('method')} />
        Email to {email ? displayEmail(email) : 'hidden'}
      </label>
      {phone && (
        <label className='mb-3 ml-2 mt-1 flex gap-2'>
          <input type='radio' value='sms' {...register('method')} />
          SMS to number ending in {phone}
        </label>
      )}
    </>
  ) : (
    <Input
      {...register('identifier')}
      label={t('emailOrMobileNumber')}
      type='text'
      error={errors?.identifier?.message}
    />
  );

  return (
    <div className='flex w-screen items-center justify-center h-screen'>
      <div className='w-full lg:w-1/2'>
        <form
          onSubmit={handleSubmit(submit)}
          className='mx-auto flex flex-1 flex-col justify-center rounded-lg px-10 py-16 md:w-4/5 md:border md:border-gray88 lg:mx-auto lg:w-[30rem] lg:flex-initial'
        >
          <div className='mb-12 flex flex-col items-center justify-center'>
            <Square className='mx-auto h-20' />
            <h1 className='mt-5 text-center text-2xl font-medium'>
              {t('signInText')}
            </h1>
          </div>

          {input}

          <button
            className='w-full rounded-lg bg-brand p-3 font-semibold uppercase text-white transition-all duration-300 hover:bg-slate-dark  disabled:opacity-50'
            disabled={result.fetching}
          >
            {t('continue')}
          </button>

          <div className='m-4 inline-flex h-6 items-center justify-center gap-2.5'>
            <div className='h-px w-full bg-black'></div>
            <div className="text-center font-['Figtree'] text-base font-medium leading-snug">
              OR
            </div>
            <div className='h-px w-full bg-black'></div>
          </div>

          <button
            type='button'
            onClick={() => setLoginWithPassword(true)}
            className='mb-4 w-full rounded-lg border border-brand bg-white p-3 font-semibold uppercase text-brand'
          >
            log in with password
          </button>
          <Link
            className='justify-between text-center text-sm font-normal underline'
            to='/forgot-password'
          >
            {t('forgotPassword')}
          </Link>
        </form>{' '}
      </div>

      <div
        className={
          'hidden items-center justify-center bg-gradient-to-b from-brand to-slate-dark h-screen lg:flex lg:w-1/2'
        }
      >
        <FullMono className='w-1/2' />
      </div>
    </div>
  );
};

function displayEmail(email: string) {
  const [local, domain] = email.split('@');
  return (
    local +
    '*'.repeat(5) +
    '@' +
    domain.charAt(0) +
    '*'.repeat(5) +
    domain.slice(1)
  );
}
