import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Listbox } from '@headlessui/react';
import {
  SelectorIcon as ChevronUpDownIcon,
  PlusIcon,
} from '@heroicons/react/solid';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SvgPlaceholderImage from '~/PlaceholderImage';
import { unique } from '~/helpers/array';
import { by, notUndefined } from '~/helpers/filter';
import { styles } from '../FindAddContact/ContactSelect';
import { SearchInput } from '../FindAddContact/SearchInput';
import { BuildingPlaceholder } from '../ui/BuildingPlaceholder';
import {
  FacetedFilterOption,
  FacetedFilterSelect,
} from '../ui/FacetedFilterSelect';
import { MenuTransition } from '../ui/Transition/MenuTransition';
import { Site } from './FindAddSite';

export type SiteSelectProps = {
  label?: string;
  error?: string;
  sites: Site[];
  value: Site[] | string[];
  onChange: (newValue: Site[]) => void;
  onCreate?: (query: string) => void;
  multiple?: boolean;
  required?: boolean;
  optionsBoxHeight?: string;
};

const isString = (value: unknown): value is string => typeof value === 'string';

const Image = ({ url }: { url?: string | null }) => (
  <div className='flex h-6 w-6 flex-shrink-0 items-center justify-center overflow-hidden rounded-[3px]'>
    {url ? (
      <img className='h-full w-full object-cover' alt='' src={url} />
    ) : (
      <span className='bg-white'>
        <SvgPlaceholderImage className='h-[22px] w-[22px] fill-current text-grey-30' />
      </span>
    )}
  </div>
);

export function SiteSelect({
  label,
  error,
  sites,
  value,
  onChange,
  onCreate,
  multiple = false,
  required = false,
  optionsBoxHeight,
}: SiteSelectProps) {
  const { t } = useTranslation();
  const confirmBtnRef = useRef<HTMLButtonElement>(null);
  const [query, setQuery] = useState('');
  const searched = query === '' ? sites : sites.filter(by(query, ['name']));

  const [filterValues, setFilterValues] = useState<
    { key: string; value: string[] }[]
  >([]);

  const filtered = searched.filter(({ attributes: siteAttributes }) =>
    filterValues.every(({ key, value }) =>
      value.some((v) =>
        siteAttributes?.find(({ name }) => name === key)?.value.includes(v)
      )
    )
  );

  const selected = value
    .map((val) => {
      return isString(val) ? sites.find((site) => site.id === val) : val;
    })
    .filter(notUndefined);

  const allFilteredSelected = filtered.every((site) =>
    selected.map(({ id }) => id).includes(site.id)
  );

  const handleChange = (newValue: Site | Site[]) => {
    if (Array.isArray(newValue)) {
      if (newValue.every((site) => site !== null)) {
        onChange(newValue);
      }
    } else if (newValue !== null) {
      onChange(newValue ? [newValue] : []);
    }
  };

  const attributeFilters: FacetedFilterOption[] = [];
  sites.forEach(({ attributes }) => {
    attributes?.forEach(({ category, name, value, type }) => {
      if (type === 'attachment') {
        return;
      }
      if (name && value.length > 0) {
        let filterIndex = attributeFilters.findIndex(({ key }) => key === name);
        if (filterIndex === -1) {
          filterIndex = attributeFilters.length;
          const group = category;
          const label = name;
          attributeFilters.push({
            $group: group?.trimEnd(),
            label: label.trimStart(),
            key: name,
            value: [],
          });
        }
        attributeFilters[filterIndex].value.push(
          ...value.filter(
            (v) =>
              v && !attributeFilters[filterIndex].value.some((o) => o === v)
          )
        );
      }
    });
  });

  return (
    <Listbox
      by='id'
      value={selected}
      onChange={handleChange}
      // TODO single selection mode
      // value={value.length ? value[0] : null}
      // onChange={(newValue) => onChange(newValue ? [newValue] : [])}
      multiple={multiple}
    >
      {({ open }) => (
        <>
          {label && (
            <Listbox.Label className={styles.label}>
              {label}
              {required && <span className='pl-0.5 text-brand'>*</span>}
            </Listbox.Label>
          )}
          <div className='relative'>
            <Listbox.Button
              className={classNames(styles.control, {
                'mb-5': label && !error,
                'py-1': selected.length,
                'py-3': !selected.length,
                'border-red-500': Boolean(error),
              })}
            >
              <div className='flex items-center gap-3'>
                <div className='flex flex-1 flex-wrap gap-2 overflow-x-clip text-left'>
                  {selected.map((site) => (
                    <div
                      key={site.id}
                      className={classNames(
                        styles.selectedOption,
                        'rounded-md'
                      )}
                    >
                      {site.image ? (
                        <Image url={site.image} />
                      ) : (
                        <BuildingPlaceholder className='!w-6 rounded-[3px] px-0 text-base' />
                      )}
                      <span className='truncate'>{site.name}</span>
                      <button
                        className='relative h-3.5 w-3.5 flex-shrink-0 rounded-full bg-[#808490] transition-colors hover:bg-[#6D6E7A]'
                        onClick={(e) => {
                          e.stopPropagation();
                          handleChange(
                            selected.filter(({ id }) => id !== site.id)
                          );
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faXmark}
                          className='absolute left-0 top-0 h-2.5 w-2.5 p-0.5 text-white'
                          // size='xs'
                        />
                      </button>
                    </div>
                  ))}
                </div>
                <div>
                  <ChevronUpDownIcon
                    className='h-5 w-5 flex-shrink-0 text-gray-400'
                    aria-hidden='true'
                  />
                </div>
              </div>
            </Listbox.Button>
            {error && <p className='ErrorMessage'>{error}</p>}
            <MenuTransition show={open}>
              <Listbox.Options
                className={classNames(
                  styles.options,
                  { 'pb-0': multiple },
                  '-mt-4 mb-5 shadow-lg',
                  optionsBoxHeight && optionsBoxHeight
                )}
              >
                <div className='sticky -top-1 z-50 -mt-1 bg-white p-1 pb-0'>
                  <SearchInput
                    className='rounded-full border-none bg-grey-3'
                    inputProps={{ className: 'bg-grey-3' }}
                    placeholder={t('site_plural')}
                    setQuery={setQuery}
                    query={query}
                  />
                  {/* <FilterGroup
                    value={filterValues}
                    onChange={setFilterValues}
                    filters={attributeFilters}
                  /> */}
                  <FacetedFilterSelect
                    label='Filter by Attribute'
                    options={attributeFilters}
                    value={filterValues}
                    onChange={setFilterValues}
                  />
                </div>
                <div className='overflow-y-auto'>
                  {onCreate && (
                    <Listbox.Option
                      key='new'
                      value={null}
                      className={styles.option}
                      onClick={() => onCreate(query)}
                    >
                      {({ active }) => (
                        <div
                          className={`flex items-center gap-2 ${
                            active ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          <div className='p-1'>
                            <PlusIcon
                              className={`h-5 w-5 ${
                                active ? 'text-white' : 'text-brand'
                              }`}
                              aria-hidden='true'
                            />
                          </div>
                          <div>{t('createSite')}</div>
                        </div>
                      )}
                    </Listbox.Option>
                  )}
                  {multiple && filtered.length > 0 && (
                    <Listbox.Option
                      key='all'
                      value={null}
                      className={styles.option}
                      onClick={() =>
                        allFilteredSelected
                          ? handleChange(
                              selected.filter(
                                ({ id: selectedId }) =>
                                  !filtered
                                    .map(({ id: filteredId }) => filteredId)
                                    .includes(selectedId)
                              )
                            )
                          : handleChange(unique([...selected, ...filtered]))
                      }
                    >
                      {({ active }) => (
                        <div className='flex items-center'>
                          <div className='flex items-center justify-center pl-4'>
                            <input
                              className={classNames(
                                'z-30 h-4 w-4 cursor-pointer accent-brand',
                                {
                                  hidden: allFilteredSelected,
                                }
                              )}
                              type='checkbox'
                              checked={false}
                            />
                            {allFilteredSelected && (
                              <label
                                className={classNames(
                                  'flex h-4 w-4 cursor-pointer items-center justify-center rounded-[2px] border border-solid border-white bg-brand pb-[3px] text-xl text-white',
                                  {
                                    'rounded-none': active,
                                  }
                                )}
                                htmlFor='box'
                              >
                                -
                              </label>
                            )}
                          </div>
                          <div
                            className={classNames(
                              'flex flex-1 items-center justify-between truncate px-2 py-2'
                            )}
                          >
                            <div className='flex items-center gap-2 truncate whitespace-nowrap pl-[1px]'>
                              <span className='truncate text-base'>
                                {t('selectAll')}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </Listbox.Option>
                  )}
                  {filtered.map((site) => (
                    <Listbox.Option
                      key={site.id}
                      value={site}
                      className={styles.option}
                    >
                      {({ selected, active }) => (
                        <div className='flex items-center'>
                          {multiple && (
                            <div className='flex items-center justify-center pl-4'>
                              <input
                                className={classNames(
                                  'z-30 h-4 w-4 cursor-pointer accent-brand',
                                  {
                                    'outline outline-[1.3px] outline-white':
                                      active && selected,
                                  }
                                )}
                                type='checkbox'
                                checked={selected}
                              />
                            </div>
                          )}
                          <div
                            className={classNames(
                              'flex flex-1 items-center justify-between truncate  py-2',
                              `${multiple ? 'px-2' : 'px-4'}`
                            )}
                            onClick={() => {
                              handleChange(site);
                              if (multiple)
                                confirmBtnRef && confirmBtnRef.current?.click();
                            }}
                          >
                            <div className='flex items-center gap-2 truncate whitespace-nowrap pl-[1px]'>
                              {site.image ? (
                                <Image url={site.image} />
                              ) : (
                                <BuildingPlaceholder
                                  className={classNames(
                                    '!h-6 !w-6 rounded-[3px] px-0 text-base',
                                    active && 'bg-shade-20'
                                  )}
                                />
                              )}
                              <span className='truncate text-base'>
                                {site.name}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </div>
                {multiple && (
                  <div className='sticky bottom-0 z-10 flex items-center justify-between border-t bg-white p-2 text-xs'>
                    <Listbox.Button className='flex-1 p-2 text-left uppercase'>
                      {t('cancel')}
                    </Listbox.Button>
                    <Listbox.Button
                      ref={confirmBtnRef}
                      className='flex-1 p-2 text-right uppercase'
                    >
                      {t('ok')}
                    </Listbox.Button>
                  </div>
                )}
              </Listbox.Options>
            </MenuTransition>
          </div>
        </>
      )}
    </Listbox>
  );
}
