import {
  AtomicBlockUtils,
  Editor as BaseEditor,
  DefaultDraftBlockRenderMap,
  EditorState,
  RawDraftContentState,
  RichUtils,
  SelectionState,
  convertFromRaw,
  convertToRaw,
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import { Map } from 'immutable';
import { useEffect, useState } from 'react';
import { s3upload } from '~/components/upload/s3upload';
import { useCreateUploadMutation } from '~/generated/graphql';
import { Toolbar } from './Toolbar';
import { createBlockRenderer } from './util';

type Props = {
  content?: RawDraftContentState | null;
  onUpdate: (rawState: RawDraftContentState) => void;
  placeholder?: string;
};

export const Editor = ({ content, placeholder, onUpdate }: Props) => {
  // Support file uploads
  const [, createUpload] = useCreateUploadMutation();

  // Support checklist blocks
  const blockRenderMap = Map({
    Checklist: {
      element: 'li',
      wrapper: {
        type: 'ul',
        props: { className: 'checklist' },
      },
      // type: ''
      mutability: 'MUTABLE',
    },
  });

  const extendedBlockRenderMap =
    DefaultDraftBlockRenderMap.merge(blockRenderMap);

  // const LINK_REGEX = RegExp(/^(ftp|http|https):\/\/[^ "]+$/);

  // const decorator = new CompositeDecorator([
  //   {
  //     strategy: findLinkEntities,
  //     component: Link,
  //   },
  //   {
  //     strategy: linkStrategy,
  //     component: PastedLink,
  //   },
  // ]);

  // Editor should be set to read only while using the mouse
  const [readOnly] = useState(false);

  const [editorState, setEditorState] = useState(
    content
      ? EditorState.createWithContent(convertFromRaw(content))
      : EditorState.createEmpty()
  );

  useEffect(() => {
    if (content) {
      setEditorState(EditorState.createWithContent(convertFromRaw(content)));
    }
  }, [content]);

  useEffect(() => {
    const update = () => {
      onUpdate(convertToRaw(editorState.getCurrentContent()));
    };
    const handle = setTimeout(update, 1000);

    return () => {
      clearTimeout(handle);
    };
  }, [editorState, onUpdate]);

  const addImage = (data: { id: string; baseUrl: string }) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'IMAGE',
      'IMMUTABLE',
      data
    );

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });

    setEditorState(
      AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ')
    );
  };

  const handleKeyCommand = (command: string, editorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }

    return 'not-handled';
  };

  const handleChange = (editorState: EditorState) => {
    setEditorState(editorState);
  };

  const customBlockRenderer = createBlockRenderer(editorState, handleChange);

  // const confirmLink = (urlValue: string) => {
  //   const contentState = editorState.getCurrentContent();
  //   const contentStateWithEntity = contentState.createEntity(
  //     'LINK',
  //     'MUTABLE',
  //     { url: urlValue }
  //   );
  //   const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  //   const newEditorState = EditorState.set(editorState, {
  //     currentContent: contentStateWithEntity,
  //   });
  //   setEditorState(
  //     RichUtils.toggleLink(
  //       newEditorState,
  //       newEditorState.getSelection(),
  //       entityKey
  //     )
  //   );
  // };

  // const removeLink = () => {
  //   const selection = editorState.getSelection();
  //   if (!selection.isCollapsed()) {
  //     setEditorState(RichUtils.toggleLink(editorState, selection, null));
  //   }
  // };

  // Support Links

  // function findLinkEntities(
  //   contentBlock: ContentBlock,
  //   callback: (start: number, end: number) => void,
  //   contentState: ContentState
  // ) {
  //   contentBlock.findEntityRanges((character) => {
  //     const entityKey = character.getEntity();
  //     return (
  //       entityKey !== null &&
  //       contentState.getEntity(entityKey).getType() === 'LINK'
  //     );
  //   }, callback);
  // }

  // function linkStrategy(
  //   contentBlock: ContentBlock,
  //   callback: (start: number, end: number) => void
  // ) {
  //   findWithRegex(LINK_REGEX, contentBlock, callback);
  // }

  // function findWithRegex(
  //   regex: RegExp,
  //   contentBlock: ContentBlock,
  //   callback: (start: number, end: number) => void
  // ) {
  //   const text = contentBlock.getText();
  //   let matchArr, start;
  //   if ((matchArr = regex.exec(text)) !== null) {
  //     start = matchArr.index;
  //     callback(start, start + matchArr[0].length);
  //   }
  // }

  const styleMap = {
    STRIKETHROUGH: {
      textDecoration: 'line-through',
    },
  };

  const uploadFiles = async (files: File[]) => {
    if (files.length && files[0] instanceof File) {
      const { lastModified, name, size, type } = files[0];
      const res = await createUpload({
        file: { form: 'image', lastModified, name, size, type },
      });
      if (res.data?.createUpload) {
        const { id, baseUrl, presignedUrl } = res.data.createUpload;

        await s3upload({
          id,
          url: presignedUrl,
          method: 'PUT',
          file: files[0] as File,
          onProgress: () => {},
        });

        addImage({ id, baseUrl });
      }
    }
  };

  const handleDroppedFiles = (selection: SelectionState, files: File[]) => {
    if (files.length && files[0] instanceof File) {
      uploadFiles(files);
      return 'handled';
    }

    return 'not-handled';
  };

  return (
    <div className='RichEditor-root mb-3 mt-1.5 rounded border border-grey-20 focus-within:border-grey-40'>
      <Toolbar editorState={editorState} setEditorState={setEditorState}>
        <Toolbar.Bold />
        <Toolbar.Italic />
        <Toolbar.Underline />
        <Toolbar.Strikethrough />
        <Toolbar.Checklist />
        <Toolbar.OrderedList />
        <Toolbar.UnorderedList />
        <Toolbar.Image onAddImage={uploadFiles} />
        {/* <Toolbar.Link /> */}
      </Toolbar>
      <div className='RichEditor-editor mb-6 mt-4 pb-2 pl-3'>
        <BaseEditor
          placeholder={placeholder}
          editorState={editorState}
          customStyleMap={styleMap}
          blockRenderMap={extendedBlockRenderMap}
          blockRendererFn={customBlockRenderer}
          handleKeyCommand={handleKeyCommand}
          handleDroppedFiles={handleDroppedFiles}
          onChange={handleChange}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
};
