import classNames from 'classnames';
import SvgPlaceholderImage from '~/PlaceholderImage';

type Props = {
  url?: string | null;
  size?: 'sm' | 'md';
};

export function Thumb({ url, size = 'sm' }: Props) {
  return (
    <div
      className={classNames(
        'flex items-center justify-center overflow-hidden rounded-[3px]',
        {
          'h-6 w-6': size === 'sm',
          'h-11 w-11': size === 'md',
        }
      )}
    >
      {url ? (
        <img className='h-full w-full object-cover' alt='' src={url} />
      ) : (
        <span className='bg-white'>
          <SvgPlaceholderImage className='text-grey-30 h-full w-full fill-current' />
        </span>
      )}
    </div>
  );
}
