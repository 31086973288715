import { yupResolver } from '@hookform/resolvers/yup';
import { Editor } from '~/components/form/editor/Editor';
// import { Editor as Lexical } from '~/components/Editor';
import classNames from 'classnames';
import { EditorState, RawDraftContentState, convertToRaw } from 'draft-js';
import { useEffect, useState } from 'react';
import { Control as BaseControl, Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import * as yup from 'yup';
import { Button } from '~/components/form/SubmitButton';
import { Input } from '~/components/form/TextField';
import { Select, SelectProps } from '~/components/form/downshift/Select';
import { useConfirm } from '~/components/ui/Confirm';
import { HelperPopup } from '~/components/ui/HelperPopup';
import {
  ItemType,
  LocationType,
  SelectStockOption,
  Status,
  StockSelection,
  StockTransferRule,
  TaskInput,
  TransferTaskInput,
  useLocationsQuery,
  useStockOnHandQuery,
} from '~/generated/graphql';
import { parseJson } from '~/helpers';
import { SideLayout } from '~/layouts/side/SideLayout';
import { TagEntityType } from '../TagCombobox';
import { useTags } from '../TagCombobox/tags';
import { AttributeSelect } from '../form/AttributeSelect';
import { Switch } from '../form/switch/Switch';
import { taskTypeOptions } from './CreateJobForm/ManageTasks';
import { StockToDispatch } from './CreateJobForm/StockToDispatch';
import { StockToReceive } from './CreateJobForm/StockToReceive';
import { DeleteTaskButton } from './DeleteTaskButton';
import { AssetTaskFields } from './TaskForm/AssetTaskFields';
import { UpdateTaskTags } from './tasks/UpdateTaskTags';

type FormValues = {
  name: string;
  description: RawDraftContentState | null | undefined;
  attributes: string[];
  tagIds: string[];
  type: string;
  required?: boolean;

  item?: {
    /** Source location ID of the form Type:id */
    locationId: string;
    supplyJob: boolean;
    selectStock: SelectStockOption;
    // AUTO
    processQueuedChanges?: boolean;
    fillTo: StockTransferRule;
    // MANUAL
    reserveStock: boolean;
    dispatch: StockSelection[];
    receive: StockSelection[];
  };

  attribute?: {
    /** List of attribute IDs to include in the task */
    attributes: string[];
  };

  purchase?: {
    spaces: string[];
  };

  stocktake?: {
    include: ItemType[];
    itemStatus: Status[];
    spaces: string[];
  };

  transfer?: TransferTaskInput;

  asset?: {
    assets: string[];
  };
};

type Props = {
  locationId?: string;
  task: TaskInput;
  taskType: string | null;
  updateTaskType: (id: string, value: string) => void;
  onApply: (task: TaskInput) => void;
  onClose: () => void;
  onDelete: (id: string) => void;
  showTaskOptions?: boolean;
};

export type Control = BaseControl<FormValues>;

const schema = yup
  .object({
    name: yup.string().required(),
  })
  .required();

function createDefaultValueRestock(): FormValues['item'] {
  return {
    /** Source location ID of the form Type:id */
    locationId: '',
    supplyJob: true,
    selectStock: SelectStockOption.MANUAL,
    // AUTO
    processQueuedChanges: false,
    fillTo: StockTransferRule.Target,
    // MANUAL
    reserveStock: true,
    dispatch: [],
    receive: [],
  };
}

function createDefaultValueStocktake(): FormValues['stocktake'] {
  return {
    include: Object.values(ItemType),
    itemStatus: [Status.Active],
    spaces: [],
  };
}

export function CustomTaskForm({
  locationId,
  task,
  taskType,
  updateTaskType,
  onApply,
  onClose,
  onDelete,
  showTaskOptions = false,
}: Props) {
  const { t } = useTranslation(['translation', 'job']);
  const [showSelect, setShowSelect] = useState<0 | 1 | null>(null);
  const scope = [locationId];

  const typeOptions = taskTypeOptions();

  const { siteId } = useParams();

  const editorState = EditorState.createEmpty();
  const defaultDescription = convertToRaw(editorState.getCurrentContent());

  const {
    control,
    formState: { isDirty, errors },
    handleSubmit,
    register,
    watch,
    setValue,
    reset,
    getValues,
  } = useForm<FormValues>({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      name: task.name ?? '',
      description: task.description
        ? parseJson(task.description)
        : defaultDescription,
      attributes: task.attributes ?? [],
      type: taskType || '',
      tagIds: task.tagIds ?? [],
      required: task.required ?? false,
      asset: { assets: task.asset?.assets ?? [] },
      item: (task.item as FormValues['item']) ?? createDefaultValueRestock(),
      attribute: task.attribute
        ? { attributes: task.attribute.attributes ?? [] }
        : { attributes: [] },
      purchase: task.purchase
        ? { spaces: task.purchase.spaces ?? [] }
        : { spaces: [] },
      stocktake: task.stocktake
        ? {
            include: task.stocktake.include ?? Object.values(ItemType),
            itemStatus: task.stocktake.itemStatus ?? [Status.Active],
            spaces: task.stocktake.spaces ?? [],
          }
        : createDefaultValueStocktake(),
      transfer: task.transfer
        ? {
            from: {
              id: task.transfer.from.id ?? '',
              spaces: task.transfer.from.spaces ?? [],
            },
            to: { id: locationId, spaces: task.transfer.to.spaces ?? [] },
          }
        : {
            from: { id: '', spaces: [] },
            to: { id: locationId, spaces: [] },
          },
    });
  }, [task]);

  const { confirm } = useConfirm({ when: isDirty });

  // If job location changes we need to reset the destination location in Transfer Stock tasks
  useEffect(() => {
    setValue('transfer.to.id', locationId ?? '');
    setValue('transfer.to.spaces', []);
  }, [locationId, setValue]);

  const apply = (data: FormValues) => {
    const {
      name,
      description,
      type,
      asset,
      item,
      stocktake,
      transfer,
      required,
    } = data;
    onApply({
      id: task.id,
      name,
      description: JSON.stringify(description),
      tagIds: data.tagIds,
      attributes: data.attributes,
      type: taskType || '',
      asset,
      item,
      attribute: data.attribute,
      purchase: data.purchase,
      stocktake,
      transfer,
      required,
    });
  };

  const statusOptions = [
    { value: Status.Active, label: t('active') },
    { value: Status.Inactive, label: t('inactive') },
  ];

  // const type = watch('type');
  const attributes = watch('attribute.attributes');
  const selectStock = watch('item.selectStock');

  function renderTaskOptions() {
    if (taskType === 'Attribute') {
      return (
        <>
          <AttributeSelect
            label='Audit Attributes'
            value={attributes}
            onChange={(selected) => setValue('attribute.attributes', selected)}
            isAttributesTask
          />
        </>
      );
    }

    if (taskType === 'Purchase') {
      return (
        <>
          <Controller
            name='purchase.spaces'
            control={control}
            render={({ field: { value, onChange } }) => (
              <SpacesSelect
                locationId={locationId}
                value={value ?? []}
                onChange={onChange}
                identifiedByName
                multiple
              />
            )}
          />
        </>
      );
    }
    if (taskType === 'Stocktake') {
      return (
        <div className='mb-40'>
          <div className='my-4 text-sm'>Include:</div>
          {Object.values(ItemType).map((value) => (
            <label key={value} className='mb-4 block pl-4 text-sm'>
              <input
                {...register('stocktake.include')}
                type='checkbox'
                value={value}
                className='mr-4 align-text-top'
              />{' '}
              {t(`itemType.${value}`, { count: 0 })}
            </label>
          ))}

          <div className='my-4 text-sm'>Item Status:</div>
          {statusOptions.map(({ value, label }) => (
            <label key={value} className='mb-4 block pl-4 text-sm'>
              <input
                {...register('stocktake.itemStatus')}
                type='checkbox'
                value={value}
                className='mr-4 align-text-top'
              />{' '}
              {label}
            </label>
          ))}

          <Controller
            name='stocktake.spaces'
            control={control}
            render={({ field: { value, onChange } }) => (
              <SpacesSelect
                locationId={locationId}
                value={value ?? []}
                onChange={onChange}
                identifiedByName
                multiple
              />
            )}
          />
        </div>
      );
    }

    // Old Move Stock/Stock Transfer version of Restock
    if (taskType === 'Restock') {
      return (
        <>
          <Controller
            name='item.locationId'
            control={control}
            render={({ field: { value, onChange } }) => (
              <SourceLocationSelect
                value={value ? [value] : []}
                onChange={(selected) => onChange(selected[0])}
              />
            )}
          />

          <div className='mb-4'>
            <label className='text-xs font-semibold text-brand'>
              <input
                {...register('item.supplyJob')}
                className='mr-4 align-text-top'
                type='checkbox'
              />
              Create job for source to supply the stock
            </label>
            <HelperPopup>
              If you don't create a corresponding job for the stock to be
              supplied, the stock will be recorded as removed from the source at
              the same time it is added or used at the destination.
            </HelperPopup>
          </div>
          <div className='mb-4 flex items-center'>
            <p className='text-sm'>Select stock</p>
            <label className='block whitespace-nowrap pl-4 text-sm'>
              <input
                {...register('item.selectStock')}
                className='mr-2 align-text-top'
                type='radio'
                value={SelectStockOption.AUTO}
              />{' '}
              Automatically
            </label>
            <label className='block whitespace-nowrap pl-4 text-sm'>
              <input
                {...register('item.selectStock')}
                className='mr-2 align-text-top'
                type='radio'
                value={SelectStockOption.MANUAL}
              />{' '}
              Manually
            </label>
            <HelperPopup>
              Automatic stock selection uses the configured Target or Capacity
              of each SKU within its assigned Space and the available stock at
              the selected source to determine how much of each SKU should be
              selected.
            </HelperPopup>
          </div>
          {selectStock === SelectStockOption.AUTO ? (
            <>
              {/* If scope is site
          <Select
            label='Asset(s) to restock'
            options={[]}
            value={[]}
            onChange={() => {}}
          /> */}

              {/* <div className='mb-4'>
              <label className='text-brand text-xs font-semibold'>
                <input
                  className='mr-4 align-text-top'
                  type='checkbox'
                  name='processQueuedChanges'
                  checked={values.processQueuedChanges}
                  onChange={() => {}}
                  // onChange={handleChange}
                />
                Process queued changes
              </label>
            </div> */}

              <div className='mb-4 flex'>
                <p className='text-sm'>Fill to</p>
                {Object.values(StockTransferRule).map((value) => (
                  <label key={value} className='block pl-4 text-sm'>
                    <input
                      {...register('item.fillTo')}
                      className='mr-2 align-text-top'
                      type='radio'
                      value={value}
                    />{' '}
                    {value}
                  </label>
                ))}
              </div>
            </>
          ) : (
            <div className='text-center'>
              <Button
                type='button'
                color='primary'
                onClick={() => setShowSelect(0)}
                disabled={!(locationId && watch('item.locationId'))}
              >
                Select Stock
              </Button>
              {!locationId ? (
                <p className='text-sm font-semibold text-delete'>
                  Job Location is required to select stock
                </p>
              ) : !watch('item.locationId') ? (
                <p className='text-sm font-semibold text-delete'>
                  Source Location is required to select stock
                </p>
              ) : null}
              {scope.length > 1 && (
                <p className='text-sm font-semibold text-copy-alt'>
                  Multiple job locations not yet supported.
                  <br />
                  Please select only one destination.
                </p>
              )}
            </div>
          )}
        </>
      );
    }

    if (taskType === 'Transfer Stock') {
      return (
        <>
          <Controller
            name='transfer.from.id'
            control={control}
            render={({ field: { value, onChange } }) => (
              <SourceLocationSelect
                value={value ? [value] : []}
                onChange={(selected) => onChange(selected[0])}
              />
            )}
          />
          <Controller
            name='transfer.from.spaces'
            control={control}
            render={({ field: { value, onChange } }) => (
              <SpacesSelect
                locationId={watch('transfer.from.id')}
                value={value ?? []}
                onChange={onChange}
              />
            )}
          />
          To:
          <Controller
            name='transfer.to.spaces'
            control={control}
            render={({ field: { value, onChange } }) => (
              <SpacesSelect
                locationId={locationId}
                value={value ?? []}
                onChange={onChange}
                multiple
              />
            )}
          />
        </>
      );
    }

    if (taskType === 'Asset') {
      return <AssetTaskFields control={control} locationId={locationId} />;
    }

    return null;
  }

  if (showSelect === 0) {
    const sourceLocationId = watch('item.locationId');
    return (
      <StockToDispatch
        location={sourceLocationId}
        initialValues={{
          reserveStock: watch('item.reserveStock') ?? '',
          dispatch: watch('item.dispatch') ?? [],
        }}
        onBack={() => setShowSelect(null)}
        onSubmit={(form) => {
          setValue('item.reserveStock', form.reserveStock);
          setValue('item.dispatch', form.dispatch);
          // if (
          //   JSON.stringify(form.dispatch) === JSON.stringify(values.dispatch)
          // ) {
          //   onChange((state) => ({ ...state, ...form }));
          // } else {
          //   // Reset receive if dispatch changed
          //   onChange((state) => ({ ...state, ...form, receive: [] }));
          // }
          setShowSelect(1);
        }}
      />
    );
  }

  if (showSelect === 1) {
    return (
      <StockToReceive
        location={scope[0]}
        dispatch={watch('item.dispatch')}
        initialValues={{ receive: watch('item.receive') ?? [] }}
        onBack={() => setShowSelect(0)}
        onSubmit={(values) => {
          setValue('item.receive', values.receive);
          setShowSelect(null);
        }}
      />
    );
  }

  const [tagOptions] = useTags(TagEntityType.Task);

  return (
    <SideLayout
      className={classNames(
        siteId ? 'absolute inset-0' : 'absolute lg:flex-1 xl:relative'
      )}
    >
      <SideLayout.Head onClose={confirm(onClose)}>Edit Task</SideLayout.Head>
      <SideLayout.Body>
        <Input
          {...register('name')}
          label={'Name *'}
          className='mb-6'
          error={errors.name?.message}
          autoFocus
        />

        <div className='mb-1 ml-1 mt-[12px] text-sm font-medium text-grey-50'>
          {t('description')}
        </div>
        <Controller
          name='description'
          control={control}
          render={({ field: { value, onChange } }) => (
            <Editor
              content={value}
              onUpdate={(raw) => {
                // console.log('updated', raw);
                onChange(raw);
              }}
              placeholder='Add Task Description...'
            />
          )}
        />

        <Controller
          name='tagIds'
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className='my-[24px]'>
              <UpdateTaskTags
                value={tagOptions.filter((t) => value?.includes(t.id))}
                options={tagOptions}
                onChange={onChange}
              />
            </div>
          )}
        />

        <Controller
          name='attributes'
          control={control}
          render={({ field }) => (
            <AttributeSelect label='Display Attributes' {...field} />
          )}
        />

        {typeOptions.length > 1 && (
          <Select
            label='Type'
            options={typeOptions}
            value={[taskType || '']}
            onChange={(selected) => updateTaskType(task.id, selected[0])}
            hasSelectorIcon
          />
        )}

        <Controller
          name='required'
          control={control}
          render={({ field }) => (
            <Switch
              name='required'
              label='Mandatory'
              help='Mandatory tasks are required to be completed prior to the job being marked as complete.'
              checked={field.value}
              onChange={(value: boolean) => field.onChange(value)}
            />
          )}
        />

        {renderTaskOptions()}
        <div className='mb-40 mt-20'>
          <DeleteTaskButton id={task.id} onDelete={onDelete} />
        </div>
      </SideLayout.Body>
      <SideLayout.Foot className='p-4'>
        <Button
          onClick={handleSubmit(apply)}
          /* On Edit Job this makes the Stocktake and Asset forms bug */
          // intent='secondary' */
        >
          {t('apply')}
        </Button>
      </SideLayout.Foot>
    </SideLayout>
  );
}

type SpacesSelectProps = {
  locationId?: string;
  identifiedByName?: boolean;
} & Pick<SelectProps, 'value' | 'onChange' | 'multiple'>;

function SpacesSelect({
  locationId,
  identifiedByName,
  ...props
}: SpacesSelectProps) {
  const { t } = useTranslation();

  const [result] = useStockOnHandQuery({
    requestPolicy: 'cache-and-network',
    variables: { location: locationId },
    pause: !locationId,
  });
  const { data } = result;

  const [all] = useQuery<{ spaceNames: string[] }>({
    requestPolicy: 'cache-and-network',
    query: '{ spaceNames }',
    pause: Boolean(locationId),
  });

  const spaceOptions =
    data?.stockOnHand
      .filter(({ space }, index, self) => {
        // Filter for unique spaces by name in the list of space skus
        return self.findIndex(({ space: { id } }) => id === space.id) === index;
      })
      .map(({ space: { id, name } }) => {
        return {
          value: identifiedByName ? name : id,
          label: name,
        };
      }) ??
    all.data?.spaceNames.map((value) => ({ value, label: value })) ??
    [];

  return (
    <Select
      {...props}
      label={t(props.multiple ? 'space_plural' : 'space')}
      options={spaceOptions}
    />
  );
}

function SourceLocationSelect(props: Pick<SelectProps, 'value' | 'onChange'>) {
  const [result] = useLocationsQuery({
    variables: {
      type: LocationType.Site,
    },
  });
  const { data } = result;

  return (
    <Select
      {...props}
      label='Source Location'
      options={
        data?.locations.map(({ __typename: type, id, name }) => ({
          value: `${type}:${id}`,
          label: name,
        })) ?? []
      }
    />
  );
}
