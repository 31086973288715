import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';
import { PopoverStateChangedEffect } from '../PopoverStateChangedEffect';
import { Pill } from './nucleus/Pill';

export type DateRange = { afterDate?: Date; beforeDate?: Date };
type Props = {
  label?: string;
  value: DateRange | null;
  onChange: (newDateRange: DateRange | null) => void;
};

export function FilterDateRange({ label = 'Date', value, onChange }: Props) {
  const [afterDate, setAfterDate] = useState<Date | undefined>(
    value?.afterDate
  );
  const [beforeDate, setBeforeDate] = useState<Date | undefined>(
    value?.beforeDate
  );

  useEffect(() => {
    // When value changes from outside, update internal state
    // so date selections stay in sync
    setAfterDate(value?.afterDate);
    setBeforeDate(value?.beforeDate);
  }, [value]);

  let [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  let [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  });

  const [cancelling, setCancelling] = useState(false);
  const handleClose = () => {
    if (cancelling) {
      setCancelling(false);
      setAfterDate(value?.afterDate);
      setBeforeDate(value?.beforeDate);
      return;
    }
    onChange({ afterDate, beforeDate });
  };
  const { t } = useTranslation();

  return (
    <>
      <Popover>
        {({ open, close: closePopover }) => {
          const getLabel = () => {
            // If the Popover is open, use the afterDate and beforeDate
            //  for the label.
            // Otherwise, use the value for the label.
            const data = open ? { afterDate, beforeDate } : value;
            let displayLabel: string;
            if (data?.afterDate && data?.beforeDate) {
              if (data.afterDate.getTime() === data.beforeDate.getTime()) {
                displayLabel =
                  'On • ' + data.afterDate.toLocaleDateString('en-AU');
              } else {
                displayLabel =
                  data.afterDate.toLocaleDateString('en-AU') +
                  ' • ' +
                  data.beforeDate.toLocaleDateString('en-AU');
              }
            } else if (data?.afterDate && !data?.beforeDate) {
              displayLabel =
                'After • ' + data.afterDate.toLocaleDateString('en-AU');
            } else if (!data?.afterDate && data?.beforeDate) {
              displayLabel =
                'Before • ' + data.beforeDate.toLocaleDateString('en-AU');
            } else {
              displayLabel = label;
            }
            return displayLabel;
          };

          return (
            <>
              <Popover.Button
                className='mx-1 my-1 rounded-full'
                ref={setReferenceElement}
              >
                <Pill
                  hasParent
                  active={open || Boolean(afterDate) || Boolean(beforeDate)}
                >
                  {getLabel()}
                  {(open
                    ? afterDate || beforeDate
                    : value?.afterDate || value?.beforeDate) && (
                    <button
                      className='max-h-full cursor-pointer pt-0.5'
                      onClick={(e) => {
                        e.stopPropagation();
                        onChange(null);
                        closePopover();
                      }}
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </button>
                  )}
                </Pill>
              </Popover.Button>
              <Popover.Panel
                ref={setPopperElement}
                className='prevent-drag-scroll border-gray-10 z-[100] mt-1 rounded-sm border bg-white shadow-md'
                style={styles.popper}
                {...attributes.popper}
              >
                <div className='grid grid-cols-2 items-center gap-x-4 p-3'>
                  <label className='text-copy-alt'>Occurs After</label>
                  <label className='text-copy-alt'>Occurs Before</label>
                  <input
                    className='mt-0.5 block w-full appearance-none whitespace-nowrap rounded border border-grey-20 bg-white px-3 py-2.5 focus-visible:border-grey-40'
                    type='date'
                    value={afterDate?.toISOString().split('T')[0]}
                    max={beforeDate?.toISOString().split('T')[0]}
                    onChange={(e) =>
                      setAfterDate(e.target.valueAsDate || undefined)
                    }
                  />
                  <input
                    className='mt-0.5 block w-full appearance-none whitespace-nowrap rounded border border-grey-20 bg-white px-3 py-2.5 focus-visible:border-grey-40'
                    type='date'
                    value={beforeDate?.toISOString().split('T')[0]}
                    min={afterDate?.toISOString().split('T')[0]}
                    onChange={(e) =>
                      setBeforeDate(e.target.valueAsDate || undefined)
                    }
                  />
                </div>
                <div className='flex border-t p-1.5 text-xs'>
                  <Popover.Button
                    className='flex-grow p-2 text-left uppercase'
                    onClick={() => setCancelling(true)}
                  >
                    {t('cancel')}
                  </Popover.Button>
                  <Popover.Button className='flex-grow p-2 text-right uppercase'>
                    {t('ok')}
                  </Popover.Button>
                </div>
              </Popover.Panel>
              <PopoverStateChangedEffect open={open} onClose={handleClose} />
            </>
          );
        }}
      </Popover>
    </>
  );
}
